<template>
  <div>
    <nav>
      <div class="nav-menu">
        <a href="/">
          <img
            src="@/assets/images/logo.png"
            alt="Logo Meo Empresas Horizontal"
            class="logo-horizon"
          />
        </a>
        <div class="nav-links">
          <!-- <a :href="pathProjects" @click="profile = false">
            <md-icon>notifications</md-icon>
          </a> -->
          <a :href="pathProjects" @click="profile = false">Projetos</a>
          <a class="nav-links-icon" href="#" @click="change()">
            <md-icon class="icon-menu">person</md-icon>
          </a>
        </div>
        <div class="nav-links toggle">
          <a class="nav-links-icon icon" href="#" @click="change()">
            <md-icon class="icon-menu">person</md-icon>
          </a>
          <a class="nav-links-icon icon" href="#" @click="menu()">
            <md-icon class="icon-menu">menu</md-icon>
          </a>
        </div>
      </div>
    </nav>
    <div class="dropdown-content-mobile" v-if="profile">
      <!-- <div class="item-menu-mobile">
        <a href="/change-password" @click="profile = false" class="item-menu-mobile-text">Alterar palavra-passe</a>
      </div> -->
      <div class="item-menu-mobile">
        <a :href="pathSettings" @click="profile = false;" class="logout"><md-icon>settings</md-icon> Configurações</a>
      </div>
      <div class="item-menu-mobile">
        <a href="#" @click="profile = false; logout()" class="logout">Terminar sessão<md-icon>keyboard_arrow_right</md-icon></a>
      </div>
    </div>
    <div class="dropdown-content-mobile" v-if="toggle">
      <div class="item-menu-mobile">
        <a :href="pathProjects" @click="profile = false" class="item-menu-mobile-text">Projetos</a>
      </div>
    </div>
  </div>
</template>

<script>
import Auth from '@/api/services/Auth.js'
export default {
  name: 'DefaultTop',
  data () {
    return {
      profile: false,
      toggle: false,
      pathProjects: '/projects',
      pathSettings: '/settings'
    }
  },
  methods: {
    settings () {
      this.profile = this.profile !== true
    },
    change () {
      this.toggle = false
      this.profile = this.profile !== true
    },
    menu () {
      this.profile = false
      this.toggle = this.toggle !== true
    },
    logout () {
      if (this.$route.path.includes('/altice')) {
        Auth.logout(true)
      } else {
        Auth.logout()
      }
    }
  },
  created () {
    if (this.$route.path.includes('altice')) {
      this.pathProjects = '/altice/projects'
      this.pathSettings = '/altice/settings'
    }
  }
}
</script>

<style lang="scss">
.logo-horizon {
  float: left;
  margin: 0 15px 0;
  width: 100px;
}
.icon-menu {
  padding: 0px;
  color: #000 !important;
  &:hover,
  &:focus {
    color: #0084D6 !important;
  }
}

.dropdown-content-mobile {
  position: absolute;
  right: 0;
  width: 23%;
  background-color: #fff;
  border: 1px solid #CCC;
  border-top:0px;
  min-width: 160px;
  padding: 12px 16px;
  z-index: 999;
  @media screen and (max-width:950px){
    width: 26%;
  }
  @media screen and (max-width:850px){
    width: 30%;
  }
  @media screen and (max-width:750px){
    width: 35%;
  }
  @media screen and (max-width:650px){
    width: 40%;
  }
  @media screen and (max-width:550px){
    width: 50%;
  }
  @media screen and (max-width:450px){
    width: 60%;
  }
  @media screen and (max-width:375px){
    width: 70%;
  }
}

.dropdown-content {
  position: absolute;
  right: 0;
  left:0;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 999;
}
.quick-links {
  margin: 0 0 18px;
  position: relative;
  // padding: 3px 0 3px 50px;
  font-size: 12px;
  text-transform: none;
  text-decoration: none;
  display: block;
  text-align: left;
}
.logout {
  font-weight: 600;
  text-transform: uppercase;
  text-align: left;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 10px;
  &:hover,
  &:focus {
    color: #0084D6 !important;
    .md-icon.md-theme-default.md-icon-font{
    color:#0084D6;
  }
  }
  .md-icon.md-theme-default.md-icon-font{
    color:#000;
    font-size:14px!important;
    padding-bottom:2px;
  }
}
.item-menu-mobile {
  font-weight: 600;
  text-transform: none;
  text-align: left;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 12px;
  &:hover,
  &:focus {
    color: #0084D6 !important;
  }
  padding: 10px;
}
.item-menu-mobile-text {
  font-weight: 400;
  text-transform: none;
  text-align: left;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 12px;
  &:hover,
  &:focus {
    .md-icon.md-theme-default.md-icon-font{
      color: #0084D6 !important;
    }
    color: #0084D6 !important;
  }
  .md-icon.md-theme-default.md-icon-font{
    color:#000;
    font-size:30px!important;
    margin-right:10px;
    margin-bottom:8px;
  }
}
.sub-menu {
  text-align: left;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 10px;
  &:hover,
  &:focus {
    color: #0084D6 !important;
  }
}
</style>
